import React from 'react';
import {
  Added,
  Improved,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="February 2022"
      subnav="release-notes">
      <div id="February2022" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          At long last, the Unified Product module components have arrived! Not
          only are they fully functional out of the box for web and mobile
          applications, but they're also supported by comprehensive guidelines,
          how-tos and examples to get you started straight away.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />
            <div className={styles.changesList}>
              <Added>
                Guidelines for how to use the new{' '}
                <Link href="/components/modules/number-list/design">
                  module system
                </Link>{' '}
                and how to choose the correct{' '}
                <Link href="/components/modules/design#Choosing%20a%20Visualization">
                  data visualization
                </Link>
                .
              </Added>
              <Added>
                Everything you need to know about the{' '}
                <Link href="/components/modules/card-group/design">
                  CardGroup
                </Link>
                .
              </Added>
              <Added>
                The new{' '}
                <Link href="/components/modules/data-card/design">
                  DataCard
                </Link>{' '}
                component.
              </Added>
              <Added>
                <Link href="/components/modules/histogram/design">
                  Histogram
                </Link>{' '}
                data visualization guidelines.
              </Added>
              <Added>
                The useful{' '}
                <Link href="/components/modules/segmented-bar/design">
                  SegmentedBar
                </Link>{' '}
                guidelines.
              </Added>
              <Added>
                Guidelines for when, and when not to, use the{' '}
                <Link href="/components/modules/toggle/design">Toggle</Link>.
              </Added>
              <Added>
                The new{' '}
                <Link href="/components/modules/bar-list/design">BarList</Link>.
              </Added>
              <Added>
                Details for the{' '}
                <Link href="/components/modules/number-list/design">
                  NumberList
                </Link>{' '}
                component.
              </Added>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.14.0" // TODO: Add version link
              versionUrl="https://github.com/hudl/uniform-ui-components/releases"
            />
            <div className={styles.changesList}>
              <Added>
                The{' '}
                <Link href="/components/modules/card-group/design">
                  CardGroup
                </Link>{' '}
                component.
              </Added>
              <Added>
                The{' '}
                <Link href="/components/modules/data-card/design">
                  DataCard
                </Link>{' '}
                component.
              </Added>
              <Added>
                The{' '}
                <Link href="/components/modules/histogram/design">
                  Histogram
                </Link>{' '}
                component.
              </Added>
              <Added>
                The{' '}
                <Link href="/components/modules/segmented-bar/design">
                  SegmentedBar
                </Link>{' '}
                component.
              </Added>
              <Added>
                The <Link href="/components/modules/toggle/design">Toggle</Link>{' '}
                component.
              </Added>
              <Added>
                The{' '}
                <Link href="/components/modules/bar-list/design">BarList</Link>{' '}
                component.
              </Added>
              <Added>
                The{' '}
                <Link href="/components/modules/number-list/design">
                  NumberList
                </Link>{' '}
                component.
              </Added>
              <Improved>
                Support for icons and tooltips in{' '}
                <Link href="/components/forms/design">form item</Link> headers
                and labels.
              </Improved>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="4.0.2"
              versionUrl="https://github.com/hudl/jarvis/releases"
            />
            <div className={styles.changesList}>
              <Added>
                The same{' '}
                <Link href="/components/modules/bar-list/code?reactnative">
                  BarList
                </Link>
                ,{' '}
                <Link href="/components/modules/card-group/code?reactnative">
                  CardGroup
                </Link>
                ,{' '}
                <Link href="/components/modules/data-card/code?reactnative">
                  DataCard
                </Link>
                ,{' '}
                <Link href="/components/modules/histogram/code?react-ative">
                  Histogram
                </Link>
                ,{' '}
                <Link href="/components/modules/number-list/code?reactnative">
                  NumberList
                </Link>
                ,{' '}
                <Link href="/components/modules/segmented-bar/code?reactnative">
                  SegmentedBar
                </Link>{' '}
                and{' '}
                <Link href="/components/modules/toggle/code?reactnative">
                  Toggle
                </Link>{' '}
                components for mobile.
              </Added>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Figma Library" />
            <div className={styles.changesList}>
              <Added>
                Interactive components for each of the above module components.
              </Added>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
